<template>
  <div class="open-list">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-input v-model="tableParams.kw" placeholder="请输入用户手机号搜索" @input="kwChange" clearable class="mr15" />
        <el-select v-model="tableParams.deviceId" placeholder="请选择门禁设备" @change="reload" clearable>
          <el-option v-for="item in deviceList" :key="item.deviceId" :label="item.deviceName"
            :value="item.deviceId">
          </el-option>
        </el-select>
      </div>
      <el-date-picker v-model="dateToDate" type="daterange" align="center" unlink-panels range-separator="~"
        start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="reload" clearable
        value-format="yyyy-MM-dd">
      </el-date-picker>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="开门时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.addDt || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备位置" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceAddress || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deviceName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作人" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.operator || "-" }}</span>
          <span>|</span>
          <span>{{ scope.row.operatorPhone || "-" }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
  </div>
</template>

<script>
import {
  getOpenList,
  getDeviceList,
} from "@/api/device/device";
import { ObjToOpt } from "@/utils/utils";
import { merchantStatusObj } from "@/db/objs";
import { pickerOptions } from "@/db/objs";
export default {
  data() {
    return {
      ObjToOpt,
      pickerOptions,
      merchantStatusObj,
      dateToDate: [],
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "",//查询关键字
        merchantId: "",//商户ID
        shopId: "",//店铺ID
        deviceId: "",//设备ID
        endDate: "",//结束日期
        startDate: "",//开始日期
      },
      deviceList: [],//设备列表
    }
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getOpenList();// 【请求】表格数据
    this.getDeviceList();// 【请求】表格数据
  },
  methods: {
    // 【请求】表格数据
    getOpenList() {
      if (this.dateToDate) {
        this.tableParams.startDate = this.dateToDate[0];
        this.tableParams.endDate = this.dateToDate[1];
      } else {
        this.tableParams.startDate = "";
        this.tableParams.endDate = "";
      }
      let data = this.tableParams;
      getOpenList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】设备列表
    getDeviceList() {
      let data = {
        page: 1,
        pageSize: 0,
        merchantId: this.tableParams.merchantId, //商户ID
        shopId: this.tableParams.shopId, //店铺ID
        deviceType: "10", //设备类型
        gatewayId: "", //网关
        deviceStatus: "", //设备状态
        bindStatus: "1", //绑定状态
      };
      getDeviceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.deviceList = res.data.list;
        }
      });
    },


    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getOpenList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getOpenList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },
  }
}
</script>

<style lang="scss" scoped>
.open-list {
  padding: .15rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
  margin-right: .15rem;
}
</style>